<template>
    <sdPageHeader title="Inspection Reports"> </sdPageHeader>
    <Main>
        <a-row :gutter="15">
            <a-col class="w-100" :md="24">
                <sdCards title="Filters ">
                    <a-form :model="filterForm" layout="vertical">
                        <a-row :gutter="25">
                            <a-col :md="6" :xs="6">
                                <a-form-item label="Inspection Companie" name="partnerId">
                                    <a-select v-if="partnerApi.length" v-model:value="filterForm.partnerId"
                                        placeholder="Inspection Companie" show-search style="width: 100%"
                                        :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                                            " :filter-option="false" @search="(value) => searchPartner(value)">
                                        <template v-if="partnerFetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                    <a-select v-else v-model:value="filterForm.partnerId"
                                        placeholder="Inspection Companie">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="date" label="Date From">
                                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateStart"
                                        :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="date" label="Date To">
                                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateEnd"
                                        :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Status" name="reportStatus">
                                    <a-select v-model:value="filterForm.reportStatus">
                                        <a-select-option :value="false">
                                            Declined </a-select-option>
                                        <a-select-option :value="true">
                                            Valid</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :span="6" class="button-container">
                                <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary"
                                    @click="onFilter()">
                                    <span>Filter</span>
                                </a-button>
                            </a-col>
                            <a-col :span="6" class="button-container">
                                <a-button class="ant-btn-lg" @click="clearFilters()" danger>
                                    <span>Clear Filters</span>
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                </sdCards>
            </a-col>
        </a-row>

        <a-row :gutter="15">
            <a-col class="w-100" :md="24">
                <sdCards title="Report List">
                    <div>
                        <TableWrapper class="table-responsive">
                            <a-table :columns="columns" :dataSource="reportData" :loading="isLoading"
                                :pagination="pagination" @change="onHandleTableChange">
                                <template #actions="{ record }">
                                    <div className="table-actions">
                                        <a-button className="edit" @click="showMessage(record.subject,
                                            record.message)">
                                            <sdFeatherIcons type="eye" size="14" />
                                        </a-button>
                                        <a-button className="delete" @click="handleDelete(record.id)">
                                            <sdFeatherIcons type="trash-2" size="14" />
                                        </a-button>
                                    </div>
                                </template>
                                <template #expandedRowRender="{ record }">
                                    <a-table class="inner-table" :columns="innerColumns" :scroll="{ x: 1000 }"
                                        :data-source="getInspectionPartnerInfo(record)" :pagination="false">
                                    </a-table>
                                </template>
                            </a-table>
                        </TableWrapper>
                    </div>
                </sdCards>
            </a-col>
        </a-row>
        <a-modal v-model:visible="visible" :title="subject" :footer="false">
            <div v-html="htmlMessage"></div>
        </a-modal>
        <ClientOffre v-if="showClientOffreModal && selectedPartner.partnerId" :partner="selectedPartner"
            :readOnly="selectedPartner.partnerOffreIsValid"
            :partnerInspectionRequestId="selectedPartnerInspectionRequestId" @update-offre="updateOffre"
            @upload-offre-file="uploadOffreFile" @delete-file="deleteOffreFile" @cancel="showClientOffreModal = false">
        </ClientOffre>
    </Main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed, defineAsyncComponent } from "vue";
import { Main, TableWrapper } from "../../styled";
import { useStore } from "vuex";
import usePartnerSearch from '@/composable/usePartnerSearch';
import moment from "moment";
import {
    FileIdItemName, FileTypeEnum
} from '@/common/Enums';
import { useFileDownload } from '@/composable/useFileDownload';
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";
import { useRouter } from "vue-router";


const { uploadFile, downloadFile } = useFileDownload();

const ClientOffre = defineAsyncComponent(() => import("@/common/components/ClientOffre.vue"));

const columns = [
    {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
    },
    {
        title: "Product Description",
        dataIndex: "productDescription",
        key: "productDescription",
    },
    {
        title: "Inspection Companies",
        dataIndex: "partnerNames",
        key: "partnerNames",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "State Of Works",
        dataIndex: "stateOfWorksNames",
        key: "stateOfWorksNames",
    },
    { title: 'Action', dataIndex: 'actions', key: 'actions', slots: { customRender: 'actions' } },

];
const innerColumns = [
    {
        title: "Inspection Companie",
        dataIndex: "partnerName",
        key: "partnerName",
    },
    {
        title: "Company's Offer",
        dataIndex: "companyOffer",
        key: "companyOffer",
    },
    {
        title: "Valid Offer ",
        dataIndex: "offreValid",
        key: "offreValid",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: 'right',
        width: 150,
    },
];
const InspectionList = defineComponent({
    name: "InspectionList",
    components: {
        Main,
        TableWrapper,
        ClientOffre
    },

    setup() {
        const { state, dispatch } = useStore();
        var reportData = ref([]);
        const router = useRouter();
        var selectedPartner = ref(null);
        var selectedPartnerInspectionRequestId = ref(null);
        var selectedInspectionRequestId = ref(null);
        var isLoading = ref(false);
        const sorted = ref({
            columnKey: 'created',
            order: 'desc',
        });
        const pagination = ref({
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            orderBy: sorted.value.columnKey,
            orderByDirection: sorted.value.order,
            total: 0,
            sorter: sorted.value,
        });

        // #endregion

        // #region filters
        var searchForm = reactive({
            layout: "vertical",
            label: "",
            code: "",
        });
        const searchFormRef = ref([]);
        const htmlMessage = ref("");
        const subject = ref("");
        const visible = ref(false);
        const partnerApi = computed(() => state.partnerApi.data);
        const showMessage = (rapportSubject, message) => {
            subject.value = rapportSubject;
            htmlMessage.value = message;
            visible.value = true;
        };
        async function inspectionReportGetAllData() {
            try {
                isLoading.value = true;
                const query = await DataService.get(
                    `api/InspectionRequest?PageSize=${pagination.value.pageSize}&PageNumber=${pagination.value.current}&dateFrom=${filterForm.dateFrom}&dateTo=${filterForm.dateTo}&partnerId=${filterForm.partnerId}&reportStatus=${filterForm.reportStatus}&OrderBy="${pagination.value.sorter.columnKey}"&OrderByDirection=${pagination.value.sorter.order}`
                );
                reportData.value = query.data.map(item => {
                    return {
                        ...item,
                        key: item.id,
                        partnerNames: item.partners.map(partner => partner.partnerName).join(', '),
                        date: moment(item.created).format('YYYY/MM/DD'),
                        stateOfWorksNames: item.stateOfWorks.map(stateOfWork => stateOfWork.name).join(', '),
                    };

                });

                pagination.value.total = query.recordsFiltered ?? query.recordsTotal;
                isLoading.value = false;
            } catch (err) {
                isLoading.value = false;
            }
        }

        const onHandleTableChange = (tablePagination, filters, sorter) => {
            sorted.value = sorter;
            pagination.value.current = tablePagination.current;
            pagination.value.orderBy = sorted.value.columnKey ?? "";
            pagination.value.orderByDirection = sorted.value.order ?? "";
            pagination.value.sorter = sorter;
            pagination.value.pageSize = tablePagination.pageSize;
            inspectionReportGetAllData();
        };
        /// filters 
        var filterForm = reactive({
            dateFrom: "",
            dateTo: "",
            partnerId: "",
            reportStatus: "",
        });

        const onFilter = () => {
            const sorter = {
                columnKey: "date",
                order: "desc",
            };

            sorted.value = sorter;
            pagination.value.current = 1;
            pagination.value.orderBy = sorted.value.columnKey ?? "date";
            pagination.value.orderByDirection = sorted.value.order ?? "descend";
            pagination.value.sorter = sorter;
            pagination.value.pageSize = 10;
            filterForm.dateFrom = filterForm.dateStart ? moment(filterForm.dateStart).format('YYYY/MM/DD') : "";
            filterForm.dateTo = filterForm.dateEnd ? moment(filterForm.dateEnd).format('YYYY/MM/DD') : "";
            inspectionReportGetAllData();

        }
        const clearFilters = () => {
            filterForm.dateStart = null;
            filterForm.dateEnd = null;
            filterForm.dateFrom = null;
            filterForm.dateTo = null;
            filterForm.partnerId = "";
            filterForm.reportStatus = "";
            onFilter();
        }

        onMounted(() => {
            inspectionReportGetAllData();
            dispatch("partnerInfo");
        });
        // #end region

        // #region Inspection offre
        var showClientOffreModal = ref(false);

        const getInspectionPartnerInfo = (inspection) => {
            return inspection.partners.map(item => {
                return {
                    key: item.partnerId,
                    partnerName: item.partnerName,
                    companyOffer: item.file ? (<a-button class="edit" onClick={() => downloadFile(item.file)}>
                        <sdFeatherIcons type="file" size={14} />
                    </a-button>) : "",
                    offreValid: item.partnerOffreIsValid ? `Yes (${item.number ?? " "})` : "No",
                    date: item.partnerOffreDate ? moment(item.partnerOffreDate).format("DD/MM/YYYY") : "",
                    price: item.price ? `${item.price} ${item.currencyName}` : "",
                    quantity: item.quantity && item.unitOfMeasureName ? `${item.quantity} ${item.unitOfMeasureName}` : "",
                    action: generateActionButtons(item, inspection),
                };
            });
        }

        const generateActionButtons = (inspectionPartnerRequest, inspection) => {
            return (
                <div class="table-actions">
                    {!inspectionPartnerRequest.partnerOffreIsValid && (
                        <a-popconfirm
                            title="Sure to validate request?"
                            ok-text="Yes"
                            onConfirm={() => handleValidate(inspectionPartnerRequest)}
                        >
                            <a>
                                <sdFeatherIcons type="check-square" size={14} />
                            </a>
                        </a-popconfirm>)}
                    <a-button class="edit" onClick={() => handleEdit(inspectionPartnerRequest, inspectionPartnerRequest.id, inspection.id)}>
                        <sdFeatherIcons type="edit" size={14} />
                    </a-button>
                </div>
            )
        }
        const handleValidate = async (requestPartner) => {
            if (requestPartner.currencyId == null) {
                notification.error({
                    message: "Missing information to validate offre",
                });
                return false;
            }
            try {
                const response = await DataService.get(
                    `/api/InspectionRequest/InspectionRequestPartner/${requestPartner.id}/Validate`
                );
                if (response) {
                    if (response.succeeded) {
                        notification.success({
                            message: "Offre validated an mail will be sent",
                        });
                        requestPartner.partnerOffreIsValid = true;
                    } else {
                        notification.error({
                            message: response.message,
                        });
                    }
                }
            } catch (err) {
                notification.error({
                    message: "Error while updating request",
                });
            }

        }
        const handleDelete = async (inspectionId) => {
            const confirm = window.confirm("Are you sure delete this?");
            if (confirm) {
                try {
                    const response = await DataService.delete(
                        `/api/InspectionRequest/${inspectionId}`
                    );
                    if (response) {
                        if (response.succeeded) {
                            notification.success({
                                message: "Inspection request deleted",
                            });
                            var foundIndex = reportData.value.findIndex((x) => x.id == inspectionId);
                            reportData.value.splice(foundIndex, 1);
                        } else {
                            notification.error({
                                message: response.message,
                            });
                        }
                    }
                } catch (err) {
                    notification.error({
                        message: "Error while deleting request",
                    });
                }
            }
            return false;

        }
        const handleEdit = (partner, partnerInspectionRequestId, inspectionId) => {
            selectedPartner.value = partner;
            selectedPartnerInspectionRequestId.value = partnerInspectionRequestId;
            selectedInspectionRequestId.value = inspectionId;
            showClientOffreModal.value = true;
        }
        const updateOffre = async (offre) => {
            offre.id = selectedPartner.value.id;
            offre.partnerId = selectedPartner.value.partnerId;
            offre.inspectionRequestId = selectedInspectionRequestId.value;
            try {
                var response;
                response = await DataService.post(
                    "api/InspectionRequest/UpdatePartnerOffre",
                    offre
                );

                if (response) {
                    if (response.succeeded) {
                        notification.success({
                            message: "Offre updated",
                        });
                        selectedPartner.value.partnerOffreIsValid = offre.partnerOffreIsValid;
                        selectedPartner.value.partnerOffreDate = offre.partnerOffreDate;
                        selectedPartner.value.unitOfMeasureName = response.data.unitOfMeasureName;
                        selectedPartner.value.currencyName = response.data.currencyName;
                        selectedPartner.value.currencyId = response.data.currencyId;
                        selectedPartner.value.unitOfMeasureId = response.data.unitOfMeasureId;
                        selectedPartner.value.price = response.data.price;
                        selectedPartner.value.quantity = response.data.quantity;
                        selectedPartner.value.contactPersonId = response.data.contactPersonId;
                        showClientOffreModal.value = false;
                    }
                } else {
                    notification.error({
                        message: response.message,
                    });
                }
            } catch (err) {
                notification.error({
                    message: "Error while updating request",
                });
            }
        }
        const GeneratePurshaseOffre = async (partner, inspectionId) => {
            const form = {
                inspectionRequestPartnerId: partner.partnerId,
                inspectionRequestId: inspectionId
            }
            try {
                var response;
                response = await DataService.post(
                    "/api/Purchase/CreatePurchseFromInspection",
                    form
                );

                if (response) {
                    if (response.succeeded) {
                        notification.success({
                            message: "Purchase Generated",
                        });

                        router.push("/purchase/edit-purchase-order/" + response.data);
                    }
                } else {
                    notification.error({
                        message: response.message,
                    });
                }
            } catch (err) {
                notification.error({
                    message: response.message,
                });
            }
        }
        const uploadOffreFile = async (file) => {
            const fileForm = {
                file: file.file,
                itemId: selectedPartnerInspectionRequestId.value,
                itemType: FileIdItemName.Partner,
                itemParentId: selectedInspectionRequestId.value,
                itemParentType: FileIdItemName.InspectionRequest,
                fileType: FileTypeEnum.InspectionCompanyOffreFile,
                name: file.file.name,
            }
            const response = await uploadFile(fileForm);
            const responseFile = response.data;
            const newfile = {
                id: responseFile.fileId,
                uid: responseFile.fileId,
                ItemId: responseFile.fileId,
                name: responseFile.filename,
                extension: responseFile.extension,
                fileName: responseFile.filename,
                fileType: responseFile.fileType,
                url: `${process.env.VUE_APP_API_ENDPOINT}api/File/download?Id=${responseFile.fileId}`,
                success: true,
                status: "done",
            };
            selectedPartner.value.file = newfile;
        }
        const deleteOffreFile = () => {
            selectedPartner.value.file = null;
        }
        // #end region

        const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
            filterForm.partnerId
        );



        return {
            onHandleTableChange,
            pagination,
            subject,
            htmlMessage,
            visible,
            showMessage,
            columns,
            searchForm,
            searchFormRef,
            reportData,
            isLoading,
            partnerApi,
            onFilter,
            clearFilters,
            partnerData,
            searchPartner,
            partnerFetching,
            filterForm,
            innerColumns,
            getInspectionPartnerInfo,
            selectedPartner,
            ClientOffre,
            showClientOffreModal,
            deleteOffreFile,
            selectedPartnerInspectionRequestId,
            updateOffre,
            GeneratePurshaseOffre,
            uploadOffreFile,
            handleDelete
        };
    },
});
export default InspectionList;
</script>
<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
    height: 38px;
}

.button-container {
    align-self: center !important;

    .ant-btn {
        height: 38px;
        width: 100%;
    }
}
</style>